











































import { Component, Prop, Vue } from 'vue-property-decorator'
import BaseIcon from '@/components/base/BaseIcon.vue'
import BaseButton from '@/components/base/BaseButton.vue'
import AddConditionForm from '@/modules/MyRoomsPage/components/AddConditionForm.vue'
import { namespace } from 'vuex-class'
import { Condition } from '@/store/modules/room.module'
const RoomStore = namespace('RoomStore')

@Component({
  name: 'AddressForm',
  components: {
    BaseIcon,
    BaseButton,
    AddConditionForm,
  },
})
export default class ConditionForm extends Vue {
  @Prop({ required: false })
  conditionForEdit!: any

  model: Condition = {
    id: null,
    weekdays: [],
    from: null,
    to: null,
    visitors: {
      min: null,
      max: null,
    },
    price: null,
    fullday: false,
  }

  @RoomStore.Mutation('setConditions')
  setConditions!: (conditions: Condition[]) => void

  @RoomStore.Mutation('setEditCondition')
  setEditCondition!: (condition: any) => void

  @RoomStore.State('conditions')
  conditions!: Condition[]

  error = ''

  public submit(): void {
    this.error = ''
    if (this.conditionForEdit) {
      this.setEditCondition(this.model)
    }
    // if ((this.$refs.form as any).checkValidity()) {
    else {
      const conditions = [...this.conditions, this.model]
      this.setConditions(conditions)
    }
    this.$emit('close')
    // }
  }
}
